<template>
	<div v-if="raskDetailInfo">
		<DrawerComponent :type="'drawer_duoci'" />
		<!-- 提示 -->
		<a-modal title="" :visible="wxchatTipsFlag" :footer="null" @cancel="wxchatTipsFlag = false" :mask="false"
			width="421px" centered :maskClosable="false">
			<div class="modaltips_container">
				<div class="modal_tips">
					<div class="img">
						<img src="../../assets/ok_icon.png" alt="" />
					</div>
					<div class="modal_tips_test">
						<div>邀请成功，已通过微信发送邀请</div>
						<div>请等待自由职业者确认</div>
					</div>
				</div>
				<div class="btn_container">
					<a-button type="primary" @click="wxchatTipsFlag = false">知道了</a-button>
				</div>
			</div>
		</a-modal>

		<div class="header-box">
			<span class="ret" @click="ret">任务管理/</span> <span>任务详情</span>
		</div>
		<div class="header-one">
			<div class="rask-title">
				<div class="rt-left">
					<div class="rt-left-title">{{ raskDetailInfo.title }}</div>
					<div class="rt-left-time">{{ raskDetailInfo.createTime }}发布</div>
				</div>
				<div class="rt-right">
					<!-- <a-button v-if="raskDetailInfo.status==='13'" @click="raskEnd">结束报名</a-button> -->
					<a-button v-if="canCancel" @click="cancelRask" style="margin-left: 12px">取消任务</a-button>
					<a-button v-if="
              raskDetailInfo.status !== '30' && raskDetailInfo.status !== '50'
            " @click="overRask" style="margin-left: 12px">任务完结</a-button>
				</div>
			</div>
			<div class="rask-content">
				<a-row :gutter="24">
					<a-col :span="8" class="rc-mgb">任务类别：{{ raskTypeName }}</a-col>
					<a-col :span="8" class="rc-mgb">任务起止日期：{{ raskDetailInfo.workBeginDate }} 至
						{{ raskDetailInfo.workEndDate }}</a-col>
					<a-col :span="8" class="rc-mgb">预计人数：{{ raskDetailInfo.needNum }}</a-col>
					<!-- <a-col :span="8" class="rc-mgb">预算金额：{{raskDetailInfo.budgetAmount===null?'--':raskDetailInfo.budgetAmount.toFixed(2)}}</a-col> -->
					<a-col :span="8" class="rc-mgb">
						任务状态：
						<span v-if="raskDetailInfo.status === '10'">待审核</span>
						<span v-if="raskDetailInfo.status === '11'">审核失败</span>
						<span v-if="raskDetailInfo.status === '13'">报名中</span>
						<span v-if="raskDetailInfo.status === '14'">报名截止</span>
						<span v-if="raskDetailInfo.status === '20'">进行中</span>
						<span v-if="raskDetailInfo.status === '30'">已完成</span>
						<span v-if="raskDetailInfo.status === '50'">已取消</span>
						<span v-if="raskDetailInfo.status === '60'">审核中</span>
						<span v-if="raskDetailInfo.status === '61'">审核未通过</span>
					</a-col>
					<a-col :span="8" class="rc-mgb">结算类型：<span>{{
              raskDetailInfo.payMode === "10" ? "单次结算" : "多次结算"
            }}</span></a-col>
					<a-col :span="8" class="rc-mgb" v-if="raskDetailInfo.resultProvide !== null">上传类型：<span>{{
              raskDetailInfo.resultProvide === "10" ? "个人上传" : "企业上传"
            }}</span></a-col>
					<a-col :span="8" class="rc-mgb">招募模式：<span v-if="raskDetailInfo.isShow == '10'">公开招募</span><span
							v-if="raskDetailInfo.isShow == '20'">非公开招募</span></a-col>
					<a-col :span="8" class="rc-mgb">费用金额类型：<span v-if="raskDetailInfo.isIncludeFee == '10'">由个人支付</span><span
							v-if="raskDetailInfo.isIncludeFee == '20'">由企业支付</span></a-col>
					<a-col :span="8" class="rc-mgb">任务描述：{{ raskDetailInfo.content }}</a-col>
				</a-row>
			</div>
		</div>
		<div class="content">
			<a-tabs :activeKey="activeTabsKey" @change="callback" tabBarStyle="{padding:0,margin:0}">
				<!-- 报名人员 -->
				<!-- <a-tab-pane key="1" :tab="totalBmry">
          <div>
            <div class="bmry">
              <a-button type="primary" :disabled="raskDetailInfo.status==='13'?false:true" @click="invite">邀请人员</a-button>
            </div>
            <s-table
              ref="bmryTable"
              style="border-top:1px solid #F0F0F0;margin-top:16px"
              :columns="columnsBmry"
              :data="dataBmry"
              :rowKey="(record) => record.id"
            >
              <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
                <a @click="sign(record)" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'">用工</a>
                <a-divider type="vertical" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'" />
                <a @click="refuse(record)" v-if="record.signStatus==='10'&&raskDetailInfo.status==='13'">拒绝</a>
              </span>
              <span slot="userName" slot-scope="text,record">
                <a-popover title="">
                  <template slot="content">
                    <div class="pop-content">
                      <div>
                        <img class="img" :src="record.avatarUrl" alt="">
                      </div>
                      <div>
                        <div class="person"><span>{{record.userName}}</span><span>{{record.phoneNo}}</span></div>
                        <div class="construction">{{record.oneselfRemark}}</div>
                      </div>
                    </div>
                    <div class="tags">{{record.oneselfLable}}</div>
                  </template>
                  <a-button style="padding:0;" type="link">
                    {{record.userName}}
                  </a-button>
                </a-popover>
              </span>
              <span slot="signStatus" slot-scope="text">
                <div class="table-point" v-if="text==='10'"><span style="background:#1890FF"></span> 已报名</div>
                <div class="table-point" v-else-if="text==='92'"><span style="background:#52C41A"></span> 已拒绝</div>
                <div class="table-point" v-else><span style="background:#BFBFBF"></span> 已取消</div>
              </span>
            </s-table>

            <a-modal
              title="发送邀请"
              width="720px"
              :visible="visibleYq"
              :footer="null"
              @cancel="handleCancelYq"
            >
              <div class="fsyq-head">
                <div v-if="!isClickYg">
                  <div class="fsyq-title">请输入需要被邀请人的手机号码</div>
                  <div>
                    <a-input-search v-model="inviteUserPhone" style="width:360px;height:32px" placeholder="请输入手机号码" @search="onSearchInvite">
                      <a-button slot="enterButton">
                        搜索
                      </a-button>
                    </a-input-search>
                  </div>
                </div>
                <div class="user-info" v-if="hasInvitor">
                  <div class="img" v-if="currentItem">{{currentItem.userName.slice(0,1)}}</div>
                  <div v-if="currentItem">{{currentItem.userName}}</div>
                </div>
              </div>
              <div class="no-invite" v-if="!hasInvitor&&stepTwoShowContentYq">
                <div>该用户暂未注册分时领英小程序平台</div>
                <div style="margin-top:20px"><a-button type="link" @click="sendDx">发送短信邀请</a-button></div>
              </div>
              <div class="common-opt">
                <a-button @click="handleCancelYq">取消</a-button>
                <a-button type="primary" style="margin-left:10px" :loading='confirmLoadingYq' :disabled="!hasInvitor" @click="handleOkYq">确认发送</a-button>
              </div>
            </a-modal>
          </div>
        </a-tab-pane> -->
				<!-- 用工人员 -->
				<a-tab-pane key="2" class="task" force-render>
					<span slot="tab" style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
						<span>{{ totalQyry }}</span>
						<a-icon type="right" style="color: #aaaaaa; margin-left: 30px" />
					</span>

					<div class="detail_search">
						<div class="search_container">
							<div class="search_item">
								<span>用户姓名：</span>
								<a-input style="width: 250px" v-model="queryParam.userName" placeholder="请输入姓名" />
							</div>
							<div class="search_item">
								<span class="span">付款状态：</span>
								<a-select style="width: 250px" placeholder="请选择状态" v-model="queryParam.status">
									<a-select-option value="10">待确认</a-select-option>
									<a-select-option value="20">已确认</a-select-option>
									<a-select-option value="30">已取消</a-select-option>
								</a-select>
							</div>
							<div class="all_btn">
								<a-button type="primary" @click="checkPayDetailTable">查询</a-button>
								<a-button style="margin-left: 12px" icon="reload" @click="resetPayDetailTable">重置</a-button>
							</div>
						</div>
					</div>

					<div class="bmry">
						<div class="new_ygry">
							<div>
								<a-button type="primary" @click="plSign" :disabled="!hasSelected">提交结算</a-button>
							</div>
							<div>
								<a-button type="default" @click="exportWorkerExcel('工作者列表')" style="margin-left: 20px">导出用工名单</a-button>
							</div>
						</div>
						<div class="yqry_tips">
							<div class="add_tips" v-if="invite_duoci === 0 && raskDetailInfo.status === '13'">
								<div class="tips_container">
									<div class="gif">
										<img src="../../assets/point.gif" alt="" />
									</div>
									<div class="tips_box">
										<div class="tips_text1">点击“邀请人员”邀请自由职业者</div>
										<div class="tips_text2" @click="noTips">不再提示</div>
									</div>
								</div>
							</div>

							<div class="new_yqry" style="padding-right: 20px" v-if="raskDetailInfo.status === '13'"
								@click="openWxCode">
								邀请二维码
							</div>
							<div class="new_yqry" style="padding-right: 20px" @click="invitePLYQ"
								v-if="raskDetailInfo.status === '13'">
								邀请人员
							</div>
						</div>
					</div>
					<div class="tips_ttttt">
						<img src="../../assets/tips.png" alt="" />
						<span>只能选择“已确认”人员提交结算</span>
					</div>
					<s-table ref="qyryTable" style="border-top: 1px solid #f0f0f0; margin-top: 16px" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }" :columns="columnsQyry" :data="dataQyry" :rowKey="(record) => record.id">
						<span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status !== '50'">
							<a @click="sign(record)" v-if="
                  record.signStatus === '10' && raskDetailInfo.status === '13'
                ">用工</a>
							<a-divider type="vertical" v-if="
                  record.signStatus === '10' && raskDetailInfo.status === '13'
                " />
							<a @click="refuse(record)" v-if="
                  record.signStatus === '10' && raskDetailInfo.status === '13'
                ">拒绝</a>
							<a-divider type="vertical" />
							<a @click="cancelSign(record)" v-if="record.signStatus === '30'">取消用工</a>
							<a-divider type="vertical" />
							<a @click="delWorker(record)">删除用工</a>
						</span>
						<span slot="signStatus" slot-scope="text">
							<div class="table-point" v-if="text === '10'">
								<span style="background: #1890ff"></span> 确认中
							</div>
							<div class="table-point" v-if="text === '20'">
								<span style="background: #1890ff"></span> 待确认
							</div>
							<div class="table-point" v-if="text === '30'">
								<span style="background: #52c41a"></span> 已确认
							</div>
							<div class="table-point" v-if="text === '93'">
								<span style="background: #f00"></span> 未接受
							</div>
							<div class="table-point" v-if="text === '96'">
								<span style="background: #bfbfbf"></span> 已取消
							</div>
							<div class="table-point" v-if="text === '95'">
								<span style="background: #bfbfbf"></span> 超时
							</div>
							<div class="table-point" v-if="text === '91'">
								<span style="background: #bfbfbf"></span> 已取消
							</div>
							<div class="table-point" v-if="text === '92'">
								<span style="background: #bfbfbf"></span> 已拒绝
							</div>
							<div class="table-point" v-if="text === '94'">
								<span style="background: #bfbfbf"></span> 已撤销
							</div>
						</span>
					</s-table>
					<!-- 签约确认单 -->
					<a-modal title="发起付款" :visible="visible" width="650px" :footer="null" @cancel="handleCancel" destroyOnClose>
						<ul class="model">
							<li>
								<span class="span">结算周期：</span>
								<div class="tag">
									<a-range-picker @change="onChangeDate" />
								</div>
							</li>
							<li>
								<span class="span">用工人员：</span>
								<div class="tag">
									<a-tag v-for="(item, index) in bmryParams.selectedTotalBmry" :key="index">{{ item.userName }}</a-tag>
									<span>共{{ bmryParams.selectedTotalBmry.length }}人</span>
								</div>
							</li>
							<li v-for="(item, index) in currentSelectedBmry" :key="index">
								<span class="span">{{ item.userName }}：</span>
								<div style="display: flex">
									<div>
										<a-input-number @change="userPayChange($event, item, index)" type="number" :maxLength="11"
											v-model="item.payAmount" style="width: 200px" min="0" placeholder="请输入任务金额"></a-input-number>
									</div>
									<div class="mgt-5" v-if="item.isAllow">
										费用金额{{ item.fyje }}元,
										<span v-if="raskDetailInfo.isIncludeFee == '20'">实际总金额为<span
												style="color: #ff4d4f">{{ item.fyzje }}元</span></span>
										<span v-if="raskDetailInfo.isIncludeFee == '10'">实际到账金额为<span
												style="color: #ff4d4f">{{ item.incomeAmount }}元</span></span>
									</div>
									<div class="mgt-5" style="color: red" v-else>
										{{ item.allowMessage }}
									</div>
								</div>
							</li>
						</ul>
						<div class="operation">
							<a-button @click="handleCancel">取消</a-button>
							<a-button @click="handleOk" :loading="confirmLoading" :disabled="!isAllow" type="primary"
								style="margin-left: 10px">确认</a-button>
						</div>
					</a-modal>
					<!-- 导入结算名单 -->

					<a-modal width="912px" :visible="importSettlementListModal" :footer="null"
						@cancel="importSettlementListModalCancel" title="导入结算名单" destroyOnClose>
						<div class="top_plyq">
							<div class="settlement_date">
								<span class="span">结算周期：</span>
								<div class="tag">
									<a-range-picker @change="onChangeDateImportSettlement" />
								</div>
							</div>

							<!-- <div class="plyq_upload">
								<a-upload name="file" :multiple="false" accept=".xlsx" :action="urlImportSettlement"
									:headers="headersPLYQ" :fileList="uploadInfoPLYQ" :data="importDataPLYQ"
									@change="uploadImportSettlementList" :showUploadList="false">
									<a-button> 批量导入 </a-button>
								</a-upload>
								<a href="https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/pay_template-v1.0.0.xlsx" target="blink"
									class="xzmb">下载模板</a>
							</div> -->
						</div>
						<div class="pl-table">
							<a-spin :spinning="importSpinning">
								<s-table ref="importSettlement" :columns="importSettlementListColumns" :data="getImportSettlementList"
									:rowKey="(record) => record.id" :scroll="{ y: 300 }">
									<span slot="action" slot-scope="text, record">
										<a v-if="record.status == '40'" @click="sendMsg(record)">发送短信</a>
									</span>
								</s-table>
							</a-spin>
						</div>
						<div class="pl-total">
							<span class="total-ze">付款总额：<span class="total-zee">{{
                  importSettlementSta.totalAmount
                }}</span><span class="total-zeee">元</span></span>

							<!-- <span class="total-ze">费用：<span class="total-zeee">{{(Math.floor(tableInfo.feeAmount * 100) / 100).toFixed(2)}}元</span></span>
            <span class="total-ze">付款总额：<span class="total-zee">{{(Math.floor(tableInfo.payAmount * 100) / 100).toFixed(2)}}</span><span class="total-zeee">元</span></span> -->
							<span class="total-ze" style="margin-left: 10px">总计人数：
								<span class="total-zee">{{ importSettlementSta.payNum }}</span><span class="total-zeee">人</span></span>
							<div class="tips" style="margin-left: 18px" v-if="importSettlementSta.exceptionNum">
								<img src="../../assets/tips.png" alt="" />
								<span>有 {{ importSettlementSta.exceptionNum }} 人导入失败，<span style="color: #1890ff; cursor: pointer"
										@click="importErrorModalOpen">查看详情</span></span>
							</div>
						</div>
						<div class="common-opt">
							<a-button @click="importSettlementCancelBtn">取消</a-button>
							<a-button type="primary" style="margin-left: 10px" :disabled="importDisabled"
								:loading="importSettlementLoading" @click="importSettlementSubmitPay">发起结算</a-button>
						</div>
					</a-modal>

					<!-- 导入异常名单 -->
					<a-modal title="导入异常名单" :visible="importErrorModal" width="650px" :footer="null"
						@cancel="importErrorModalCancel" destroyOnClose>
						<s-table ref="qyryTable" style="border-top: 1px solid #f0f0f0; margin-top: 16px"
							:columns="importErrorColumns" :data="getImportErroeList" :rowKey="(record) => record.id">
						</s-table>
						<div class="common-opt">
							<a-button @click="importErrorModalCancel">取消</a-button>
							<a-button type="primary" style="margin-left: 10px" @click="importErrorModalCancel">确认</a-button>
						</div>
					</a-modal>

					<!-- 邀请人员 -->
					<a-modal width="912px" :visible="visiblePLYQ" :footer="null" @cancel="handleCancelPLYQ" destroyOnClose>
						<div class="task_title_modal" slot="title">
							任务创建成功，请邀请自由职业者
						</div>
						<div class="top_plyq">
							<div class="phone_search">
								<a-input-search v-model="inviteUserPhone" style="width: 300px; height: 32px" placeholder="请输入手机号码"
									@search="onSearchInvite">
									<a-button slot="enterButton"> 添加 </a-button>
								</a-input-search>
							</div>

							<div class="search_item">
								<span class="">注册状态：</span>
								<a-select style="width: 150px" placeholder="请选择状态" v-model="queryLoginStatus"
									@change="loginStatusChange">
									<a-select-option value="0">未注册</a-select-option>
									<a-select-option value="1">已注册</a-select-option>
								</a-select>
							</div>

							<div class="plyq_upload">
                <a-upload
                  name="file"
                  :multiple="false"
                  accept=".xlsx"
                  :action="urlPLYQ"
                  :headers="headersPLYQ"
                  :fileList="uploadInfoPLYQ"
                  :data="importDataPLYQ"
                  @change="handleChangePLYQ"
                  :showUploadList="false"
                >
                  <a-button> 导入数据 </a-button>
                </a-upload>
                <a
                  href="https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/%E9%95%BF%E6%9C%9F%E4%BB%BB%E5%8A%A1%E6%89%B9%E9%87%8F%E9%82%80%E8%AF%B7%E6%A8%A1%E6%9D%BFv2.0.xlsx"
                  target="blink"
                  class="xzmb"
                  >下载模板</a
                >
              </div>
						</div>

						<div class="pl-table">
							<a-spin :spinning="inviteSpinning">
								<s-table ref="raskTablePLYQ" :columns="columnsPLYQ" :data="raskDataPLYQ" :rowKey="(record) => record.id"
									:scroll="{ y: 300 }">
									<span slot="action" slot-scope="text, record">
										<a v-if="record.loginStatus === 0" @click="sendMsg(record)">发送短信</a>
									</span>
								</s-table>
							</a-spin>
						</div>
						<div class="pl-total">
							<!-- <span class="total-ze">任务总额：<span class="total-zee">{{(Math.floor(tableInfoPLYQ.taskAmount * 100) / 100).toFixed(2)}}</span><span class="total-zeee">元</span></span> -->

							<!-- <span class="total-ze">费用：<span class="total-zeee">{{(Math.floor(tableInfo.feeAmount * 100) / 100).toFixed(2)}}元</span></span>
            <span class="total-ze">付款总额：<span class="total-zee">{{(Math.floor(tableInfo.payAmount * 100) / 100).toFixed(2)}}</span><span class="total-zeee">元</span></span> -->
							<span class="total-ze">总计人数：<span class="total-zeee">{{ tableInfoPLYQ.countNum }}人</span></span>
						</div>
						<div class="common-opt">
							<a-button @click="handleCancelPLYQ">取消</a-button>
							<a-button type="primary" style="margin-left: 10px" :disabled="!allowSubmit" :loading="confirmLoadingPl"
								@click="handleOkPLYQ">确认</a-button>
						</div>
					</a-modal>

					<!-- 搜索结果 -->
					<a-modal title="发送邀请" width="720px" :visible="visibleYq" :footer="null" @cancel="handleCancelYq"
						destroyOnClose>
						<div class="fsyq-head">
							<div class="user-info" v-if="hasInvitor">
								<div class="img" v-if="currentItem">
									{{ currentItem.userName.slice(0, 1) }}
								</div>
								<div v-if="currentItem">{{ currentItem.userName }}</div>
							</div>
						</div>

						<div class="no-invite" v-if="!hasInvitor && stepTwoShowContentYq">
							<div>该用户暂未注册分时领英小程序平台</div>
							<div style="margin-top: 20px">
								<a-button type="link" @click="sendDx">发送短信邀请</a-button>
							</div>
						</div>
						<div class="common-opt">
							<a-button @click="handleCancelYq">取消</a-button>
							<a-button type="primary" style="margin-left: 10px" :loading="confirmLoadingYq" :disabled="!hasInvitor"
								@click="handleOkYq">确认发送</a-button>
						</div>
					</a-modal>
				</a-tab-pane>
				<!-- 结算付款 -->
				<a-tab-pane key="3" :tab="totalFkxx">
					<div class="fk_tab">
						<div class="add_tips1" v-if="settlement_duoci === 0 && fkjsList.length > 0">
							<div class="tips_container">
								<div class="gif">
									<img src="../../assets/point.gif" alt="" />
								</div>
								<div class="tips_box">
									<div class="tips_text1">结算单已生成，点击查看详情</div>
									<div class="tips_text2" @click="noTips1">不再提示</div>
								</div>
							</div>
						</div>
						<s-table style="border-top: 1px solid #f0f0f0; margin-top: 16px" ref="yfkTable" :columns="columnsYfkjsd"
							:data="dataYfkjsd" :rowKey="(record) => record.id">
							<span slot="cycle" slot-scope="text, record">
								<span>{{ record.payPeriodStartTime }} ~
									{{ record.payPeriodEndTime }}</span>
							</span>
							<span slot="action" slot-scope="text, record">
								<!-- <a @click="payDetail(record)">付款详情</a>
              <a-divider type="vertical" />
              <a-button style="padding:0" type="link" @click="submitPay(record)" :disabled="(record.payStatus==='40'&&record.payAmount!==0)?false:true">提交结算</a-button> -->
								<a @click="settlementDetail(record)">查看结算单 </a>
							</span>
						</s-table>
					</div>
					<!-- 详情 -->
					<a-modal title="付款详情" :visible="visibleTaskDetail" @cancel="visibleTaskDetail = false" width="1000px"
						class="detail-modal" :footer="null" destroyOnClose>
						<div v-if="raskDetailInfo.resultProvide === '20'" style="margin: 0 0 20px 0">
							<a-alert
								message="请您以认真谨慎的态度上传有效的服务成果。服务成果须一目了然的反映出您从事的业务内容及完成情况，服务成果包括但不限于：服务人员本人在服务场景中的照片（能够看到产品或企业logo）、设计效果图、服务的方案/计划书、绩效费发放规则、公司系统中的销售及提佣数据、专业技术服务对应的服务成果等(支持doc、docx、xls、xlsx、pdf、jpg等格式)，以上成果证据均需企业或自然人签字（盖章）。如服务成果不能提供客观图像资料，可能无法通过平台开具发票。"
								banner />
						</div>
						<div>
							<a-button type="primary" @click="plUpload"
								v-if="disabledBtn2 && raskDetailInfo.resultProvide === '20'">统一上传</a-button>
							<a-button type="primary" v-if="!disabledBtn2 && raskDetailInfo.resultProvide === '20'"
								disabled="true">统一上传</a-button>
						</div>
						<s-table style="border-top: 1px solid #f0f0f0; margin-top: 16px" ref="yfkTableDetail"
							:columns="columnsWkjsd" :data="dataWkjsd" :rowKey="(record) => record.id">
							<span slot="cycle" slot-scope="text, record">
								<span>{{ record.payPeriodStartTime }} ~
									{{ record.payPeriodEndTime }}</span>
							</span>
							<span slot="action" slot-scope="text, record">
								<a @click="cancelPays(record)" v-if="record.payStatus === '10'">取消付款</a>
								<a-divider type="vertical" v-if="
                    record.payStatus === '10' &&
                    raskDetailInfo.resultProvide &&
                    raskDetailInfo.resultProvide === '20'
                  " />
								<a @click="uploadResult(record)" v-if="
                    record.payStatus === '10' &&
                    raskDetailInfo.resultProvide &&
                    raskDetailInfo.resultProvide === '20'
                  ">上传结果</a>
							</span>
						</s-table>
						<a-modal title="上传任务结果" :confirmLoading="confirmLoadingUpload" width="600px" destroyOnClose
							:visible="visibleExamineModal" @ok="handleRechargeModalOk" @cancel="handleExamineModalCancel">
							<a-form :form="examinForm">
								<a-row class="form-row">
									<a-col :lg="24" :md="24" :sm="24">
										<a-form-item label="任务结果" :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
											:wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }">
											<a-upload :action="url.frontUrl" :multiple="true" :headers="headers" :file-list="fileList"
												:data="uploadData" :beforeUpload="beforeUpload" @change="handleChange">
												<a-button> <a-icon type="upload" /> 上传 </a-button>
											</a-upload>
										</a-form-item>
									</a-col>
									<a-col :lg="24" :md="24" :sm="24">
										<a-form-item label="备注" :labelCol="{ lg: { span: 4 }, sm: { span: 5 } }"
											:wrapperCol="{ lg: { span: 20 }, sm: { span: 19 } }">
											<a-textarea placeholder="请填写备注" v-decorator="[
                          'content',
                          {
                            rules: [
                              {
                                required: false,
                                message: '请填写备注',
                                whitespace: true,
                              },
                            ],
                          },
                        ]"></a-textarea>
										</a-form-item>
									</a-col>
								</a-row>
							</a-form>
						</a-modal>
					</a-modal>
				</a-tab-pane>
				<!-- 任务审核 -->
				<!-- <a-tab-pane key="4" :tab="totalRwsh">
          <div style="margin:10px 0;"><a-alert message="请您以认真谨慎的态度上传有效的服务成果。服务成果须一目了然的反映出您从事的业务内容及完成情况，服务成果包括但不限于：服务人员本人在服务场景中的照片（能够看到产品或企业logo）、设计效果图、服务的方案/计划书、绩效费发放规则、公司系统中的销售及提佣数据、专业技术服务对应的服务成果等(支持doc、docx、xls、xlsx、pdf、jpg等格式)，以上成果证据均需企业或自然人签字（盖章）。如服务成果不能提供客观图像资料，可能无法通过平台开具发票。" banner /></div>
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :lg="6" :md="8" :sm="12">
                <a-form-item label="用户名称">
                  <a-input style="width:200px" v-model="queryParam.userName" placeholder="请输入姓名"/>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="24">
                <a-form-item label="审核状态">
                  <a-select style="width:200px" placeholder="请选择审核状态" v-model="queryParam.auditStatus">
                    <a-select-option value="10">企业待审核</a-select-option>
                    <a-select-option value="20">已通过</a-select-option>
                    <a-select-option value="30">已驳回</a-select-option>
                    <a-select-option value="11">平台待审核</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button style="margin-left: 12px;" type="primary" @click="handleToSearchEnterprises">查询</a-button>
                  <a-button style="margin-left: 12px;" icon="reload" @click="resetSearchEnterprises">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
          <div>
            <s-table
            style="border-top:1px solid #F0F0F0;margin-top:16px;"
            ref="rwshTable"
            :columns="columnsDsh"
            :data="dataDsh"
            :rowKey="(record) => record.id"
          >
            <span slot="cycle" slot-scope="text, record">
              <span>{{record.payPeriodStartTime}} ~ {{record.payPeriodEndTime}}</span>
            </span>
            <span slot="action" slot-scope="text, record" v-if="raskDetailInfo.status!=='50'">
              <a @click="pass(record)" v-if="record.auditStatus==='10'">通过</a>
              <a-divider type="vertical" v-if="record.auditStatus==='10'" />
              <a @click="noPass(record)" v-if="record.auditStatus==='10'">驳回</a>
            </span>
            <div slot="items" slot-scope="text, record">
              <a @click="showPic(record)">查看</a>

              <a-modal
                title="任务结果查看"
                :visible="visiblePicture"
                :footer="null"
                @cancel="visiblePicture=false"
              >
                <ul class="pic-select">
                  <li v-for="(item,index) in picList" :key="index"><a :href="item.fileUrl" target="_blank">{{item.fileName===null?`自由职业者任务成果${index+1}`:item.fileName}}</a></li>
                  <li>任务结果描述：{{currentContent}}</li>
                </ul>

              </a-modal>
            </div>
            </s-table>
            <a-modal
              title="驳回原因"
              :visible="visibleBh"
              @ok="handleSubmit"
              @cancel="handleCancelBh"
            >
              <a-form :form="formBh" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                <a-form-item label="驳回原因">
                  <a-textarea
                    :autoSize="{ minRows: 3, maxRows: 5 }"
                    :maxLength="100"
                    v-decorator="['errorInfo', { rules: [{ required: true, message: '请输入驳回原因!' }] }]"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </div>
        </a-tab-pane> -->
			</a-tabs>
		</div>

		<!-- 二维码邀请 -->
		<!-- <div class="wx-login" v-show="showWxCode"> -->
		<!-- <div class="wx-login-container"> -->
		<!-- <div class="close-arrow"><a-icon @click="closeWxCode" type="close" style="color:#FFFFFF;font-size:18px;cursor:pointer" /></div> -->
		<!-- <div class="wx-login-content"> -->
		<!-- <div class="wx-login-title">二维码邀请</div> -->
		<!-- <div class="wx-login-footer" style="padding-bottom:10px">请使用微信扫描二维码</div> -->
		<!-- 二维码 -->
		<!-- <div class="wx-code"><div id="qrcode" ref="qrCodeDiv"></div></div> -->
		<!-- <div class="wx-login-save-pic" @click="downLoadQrCode">保存图片</div> -->

		<!-- </div> -->
		<!-- </div> -->
		<!-- </div> -->
		<QRcodeShow :taskId="raskId" :payMode="raskDetailInfo.payMode" @closeQRcode="closeQRcode"
			:showQRcode="showWxCode" />
	</div>
</template>
<script>
	import STable from "@/components/table/";
	import moment from "moment";
	import {
		ACCESS_TOKEN
	} from "@/store/mutation-types";
	import DrawerComponent from "../components/drawer";
	import Vue from "vue";

	import QRcodeShow from "./component/QRcodeShow.vue";

	import {
		raskDetail,
		raskCancel,
		taskOver,
		raskEnd,
		getWorkList,
		raskAssign,
		refuseAssign,
		cancelAssign,
		getRaskTypeName,
		taskWorkerPayItem,
		settlementStatistical,
		getPayDetails,
		taskWorkerPaySubmit,
		taskWorkerResult,
		getByWorkerIds,
		getUserByPhone,
		invitation,
		sendInvitationSms,
		invitationRecommendUser,
		getServiceRule,
		addPeriodPay,
		getOrderList,
		taskWorkerPayItemList,
		cancelPay,
		submitPeriodPay,
		fulFilTask,
		enterpriseAdd,
		enterpriseUnifyAdd,
		queryImportWorkerList,
		phoneInvitation,
		importWorkerStatistics,
		submitApply,
		getImportPayList,
		getImportPayStatistics,
		submitImportPay,
		delWorker,
		exportWorker,
		getSysRole,
	} from "@/api/api";
	import {
		createLogger
	} from "vuex";
	export default {
		name: "raskDetail",
		components: {
			STable,
			DrawerComponent,
			QRcodeShow,
		},
		data() {
			const cellStyle = {
				maxWidth: "120px",
				overflow: "hidden",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis",
				cursor: "pointer",
			};
			return {
				headers: {
					"X-Access-Token": Vue.ls.get(ACCESS_TOKEN)
				},
				queryParam: {
					userName: "",
					status: undefined,
				},
				isClickYg: false,
				visibleTaskDetail: false,
				visiblePicture: false,
				visibleBh: false,
				columnsBmry: [{
						title: "姓名",
						dataIndex: "userName",
						scopedSlots: {
							customRender: "userName"
						},
					},
					{
						title: "联系电话",
						dataIndex: "phoneNo",
						customRender: (text) => (text === null ? "--" : text),
					},
					{
						title: "来源",
						dataIndex: "joinType",
						customRender: (text) => (text === "10" ? "报名" : "邀请"),
					},
					{
						title: "用工状态",
						dataIndex: "signStatus",
						customRender: (text) => {
							if (text === "10") {
								return "确认报名";
							}
							if (text === "91" || text === "92") {
								return "报名失败";
							}
							if (text === "20") {
								return "合作确认";
							}
							if (text === "30") {
								return "合作中";
							}
							if (
								text === "93" ||
								text === "94" ||
								text === "95" ||
								text === "96"
							) {
								return "合作失败";
							}
						},
					},
					{
						title: "原因",
						dataIndex: "signStatus",
						customRender: (text) => {
							if (text === "10" || text === "20" || text === "30") {
								return "--";
							}
							if (text === "91") {
								return "用户取消报名";
							}
							if (text === "92") {
								return "企业拒绝";
							}
							if (text === "93") {
								return "工作者拒绝合作";
							}
							if (text === "94") {
								return "企业取消合作";
							}
							if (text === "95") {
								return "超时系统自动取消";
							}
							if (text === "96") {
								return "企业取消任务";
							}
						},
					},
					{
						title: "报名时间",
						dataIndex: "createTime",
						customRender: (text) => (text === null ? "--" : text),
					},
					{
						title: "操作",
						dataIndex: "action",
						scopedSlots: {
							customRender: "action"
						},
					},
				], //报名人员
				totalBmry: "报名人员",
				totalFkxx: "结算付款",
				totalRwsh: "任务审核",
				bmryParams: {
					selectedTotalBmry: [],
					prePay: 0,
					raskRash: 0,
					users: [],
					taskId: "",
					payPeriodEndTime: "",
					payPeriodStartTime: "",
				},
				taxPoint: "",
				selectedRowKeys: [],
				confirmLoading: false,
				confirmLoadinginvite: false,
				visible: false,
				visibleinvite: false,
				canCancel: true,
				columnsQyry: [{
						title: "姓名",
						dataIndex: "userName",
						customRender: (text) => (text === null ? "--" : text),
					},
					{
						title: "联系电话",
						dataIndex: "phoneNo",
						customRender: (text) => (text === null ? "--" : text),
					},
					// {
					//   title: '任务金额',
					//   dataIndex: 'signAmount',
					//   customRender: text => text === null?'--':text.toFixed(2)
					// },
					{
						title: "用工状态",
						dataIndex: "signStatus",
						scopedSlots: {
							customRender: "signStatus"
						},
					},
					{
						title: "报名时间",
						dataIndex: "createTime",
						customRender: (text) => (text === null ? "--" : text),
					},
					{
						title: "操作",
						dataIndex: "action",
						scopedSlots: {
							customRender: "action"
						},
					},
				], //签约人员
				totalQyry: "用工人员",
				columnsDsh: [{
						title: "提交时间",
						dataIndex: "createTime",
						defaultSortOrder: "descend",
						sorter: (a, b) =>
							moment(a.createTime).unix() - moment(b.createTime).unix(),
						width: "120px",
					},
					{
						title: "用工周期",
						dataIndex: "cycle",
						width: "200px",
						scopedSlots: {
							customRender: "cycle"
						},
					},
					{
						title: "姓名",
						dataIndex: "userName",
						customRender: (text) => (text === null ? "--" : text),
						width: "80px",
					},
					{
						title: "联系电话",
						dataIndex: "phoneNo",
						customRender: (text) => (text === null ? "--" : text),
						width: "100px",
					},
					{
						title: "任务结果",
						dataIndex: "items",
						scopedSlots: {
							customRender: "items"
						},
						width: "100px",
					},
					{
						title: "审核状态",
						dataIndex: "auditStatus",
						customRender: (text) =>
							text === "10" ?
							"企业待审核" :
							text === "20" ?
							"已通过" :
							text === "11" ?
							"平台待审核" :
							"已驳回",
						width: "100px",
					},
					{
						title: "驳回原因",
						dataIndex: "errorInfo",
						customRender: (text) => (text === null ? "--" : text),
						width: "100px",
					},
					{
						title: "处理时间",
						dataIndex: "auditTime",
						width: "120px",
					},
					{
						title: "操作",
						dataIndex: "action",
						scopedSlots: {
							customRender: "action"
						},
						width: "130px",
						fixed: "right",
					},
				], //任务审核
				columnsYfkjsd: [{
						title: "创建时间",
						dataIndex: "createTime",
					},
					{
						title: "用工周期",
						dataIndex: "cycle",
						scopedSlots: {
							customRender: "cycle"
						},
					},
					{
						title: "付款人数",
						dataIndex: "payNum",
						customRender: (text) => (text === null ? "--" : text),
					},
					{
						title: "付款金额",
						dataIndex: "payAmount",
						customRender: (text) => (text === null ? "--" : text.toFixed(2)),
					},
					{
						title: "付款状态",
						dataIndex: "payStatus",
						customRender: (text) => this.workerOrderPayStatus[text],
					},
					{
						title: "操作",
						dataIndex: "action",
						scopedSlots: {
							customRender: "action"
						},
					},
				], //预付款结算单
				yfkPre: null,
				yfkLater: null,
				notYfuJs: true,
				columnsWkjsd: [{
						title: "姓名",
						dataIndex: "userName",
					},
					{
						title: "联系电话",
						dataIndex: "phoneNo",
					},
					{
						title: "支付金额",
						dataIndex: "payAmount",
						customRender: (text) => (text === null ? "--" : text.toFixed(2)),
					},
					{
						title: "付款状态",
						dataIndex: "payStatus",
						customRender: (text) =>
							text === "10" ?
							"等待上传任务结果" :
							text === "20" ?
							"待支付" :
							text === "30" ?
							"支付处理中" :
							text === "50" ?
							"取消付款" :
							text === "11" ?
							"已上传任务结果" :
							"支付成功",
					},
					{
						title: "操作",
						dataIndex: "action",
						scopedSlots: {
							customRender: "action"
						},
					},
				], //尾款结算单
				wkPre: null,
				wkLater: null,
				notWkJs: true,
				raskDetailInfo: null,
				raskTypeName: "",
				raskId: "",
				formLayout: "horizontal",
				formBh: this.$form.createForm(this),
				examinForm: this.$form.createForm(this),
				record: null,
				expand: false,
				tjjsLoading: false,
				tjjsWkLoading: false,
				invitePhone: "",
				inviteUserList: [],
				disabledBtn1: true,
				disabledBtn2: true,
				visibleYq: false,
				confirmLoadingYq: false,
				yfje: 0,
				rwje: 0,
				inputYfje: 0,
				hasInvitor: false, // 是否查询到邀请用户
				visibleYg: false, //确认用工
				currentItem: null,
				searchInviteUser: "",
				inviteUserPhone: "",
				stepTwoShowContentYq: false,
				picList: [],
				currentSelectedBmry: [],
				currentContent: "",
				isAllow: true,
				allowMessage: "",
				uploadRecord: "",
				visibleExamineModal: false,
				fileList: [],
				url: {
					frontUrl: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
				},
				uploadData: {
					fileType: "103206",
				},
				confirmLoadingUpload: false,
				isPl: false,
				visiblePLYQ: false, //批量邀请人员
				urlPLYQ: window._CONFIG["domianURL"] +
					"/slash-youth/client/b/task/importWorkerMorePay",
				urlImportSettlement: window._CONFIG["domianURL"] +
					"/slash-youth/client/b/taskWorkerPay/importPay",
				headersPLYQ: {
					"X-Access-Token": Vue.ls.get(ACCESS_TOKEN)
				},
				uploadInfoPLYQ: [],
				uploadInfoPLYQ1: [],
				importDataPLYQ: {
					taskId: "",
				},
				columnsPLYQ: [{
						title: "姓名",
						dataIndex: "userName",
					},
					{
						title: "电话",
						dataIndex: "phoneNo",
					},
					{
						title: "身份证号码",
						dataIndex: "idcardNo",
					},
					{
						title: "注册状态",
						dataIndex: "loginStatus",
						customRender: (text) => (text === 1 ? "已注册" : "未注册"),
					},
					{
						title: "操作",
						dataIndex: "action",
						scopedSlots: {
							customRender: "action"
						},
					},
				],
				tableInfoPLYQ: {
					taskAmount: 0.0,
					advanceAmount: 0.0,
					balanceAmount: 0.0,
					feeAmount: 0.0,
					payAmount: 0.0,
					countNum: 0.0,
				},
				inviteSpinning: false, //邀请人员加载状态
				batchIdPLYQ: "666",
				confirmLoadingPl: false,
				dataPl: [],
				allowSubmit: false,
				invite_duoci: "",
				drawer_duoci: "",
				settlement_duoci: "",
				wxchatTipsFlag: false,
				fkjsList: [],
				activeTabsKey: "2",

				importSettlementSta: {
					exceptionNum: 0, //异常人数
					payNum: 0, //付款人数
					totalAmount: "0.00", //付款总额
				},
				importSettlementListDate: [],
				importSettlementBatchId: "666",
				payPeriodStartTime: "",
				payPeriodEndTime: "",
				importDisabled: true,
				importSettlementListModal: false,
				importSettlementLoading: false,
				importSpinning: false,
				importSettlementTableStatus: {
					10: "已确认",
					20: "未签约",
					30: "未邀请",
					40: "未注册",
				},
				importSettlementListColumns: [{
						title: "姓名",
						dataIndex: "userName",
					},
					{
						title: "电话",
						dataIndex: "phoneNo",
					},
					{
						title: "身份证号码",
						dataIndex: "idcardNo",
					},
					{
						title: "任务金额",
						dataIndex: "payAmount",
						customRender: (text) => (text == null ? "--" : text.toFixed(2)),
					},
					{
						title: "用工状态",
						dataIndex: "status",
						customRender: (text) => this.importSettlementTableStatus[text],
					},
					{
						title: "操作",
						dataIndex: "action",
						scopedSlots: {
							customRender: "action"
						},
					},
				],
				importErrorModal: false,
				importErrorColumns: [{
						title: "姓名",
						dataIndex: "userName",
					},
					{
						title: "电话",
						dataIndex: "phoneNo",
					},
					{
						title: "身份证号码",
						dataIndex: "idcardNo",
					},
					{
						title: "任务金额",
						dataIndex: "payAmount",
						customRender: (text) => (text == null ? "--" : text.toFixed(2)),
					},
					{
						title: "用工状态",
						dataIndex: "status",
						customRender: (text) => this.importSettlementTableStatus[text],
					},
				],

				queryLoginStatus: undefined,

				workerOrderPayStatus: {
					10: "提交成功",
					20: "复核成功",
					21: "付款处理中",
					30: "付款成功",
					40: "待提交",
					50: "未达到支付条件",
					60: "取消支付",
				},

				showWxCode: false,
				imgData: "",
				qrcodeDivCreate: null,
				noConfirmTask: false
			};
		},
		computed: {
			// 费用
			fy() {
				if (this.rwje) {
					return (this.rwje * this.taxPoint).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 总金额
			allFy() {
				if (this.rwje) {
					return (this.rwje * this.taxPoint + Number(this.rwje)).toFixed(2);
				} else {
					return 0.0;
				}
			},
			wk() {
				if (this.inputYfje && this.rwje) {
					return (this.rwje - this.inputYfje).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 报名人员是否已选择
			hasSelected() {
				if (
					this.raskDetailInfo.status === "20" ||
					this.raskDetailInfo.status === "30" ||
					this.raskDetailInfo.status === "50"
				) {
					return false;
				}
				return this.selectedRowKeys.length > 0;
			},
			// 平均每人任务金额
			avregeCash() {
				if (this.bmryParams.selectedTotalBmry.length > 0) {
					return (
						this.bmryParams.raskRash / this.bmryParams.selectedTotalBmry.length
					).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 费用
			fyCash() {
				if (this.bmryParams.selectedTotalBmry.length > 0) {
					console.log(this.bmryParams.raskRash, this.taxPoint);
					return (this.bmryParams.raskRash * this.taxPoint).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 平均每人预付金额
			prePayCash() {
				if (this.bmryParams.selectedTotalBmry.length > 0) {
					return (
						(this.bmryParams.raskRash * this.bmryParams.prePay) /
						this.bmryParams.selectedTotalBmry.length /
						100
					).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 实际金额
			localPay() {
				if (this.bmryParams.selectedTotalBmry.length > 0) {
					return (
						Number(this.bmryParams.raskRash) +
						this.bmryParams.raskRash * this.taxPoint
					).toFixed(2);
				} else {
					return 0.0;
				}
			},
			// 统计
			tjPrePay() {
				let s =
					"预付款" +
					(this.prePayCash * this.selectedRowKeys.length).toFixed(2) +
					" 剩余尾款" +
					(
						(this.avregeCash - this.prePayCash) *
						this.selectedRowKeys.length
					).toFixed(2);
				return s;
			},
		},
		created() {
			let pageTips = window.localStorage.getItem("allTipsFlag");
			if (!pageTips) {} else {
				this.invite_duoci = JSON.parse(pageTips).invite_duoci;
				this.drawer_duoci = JSON.parse(pageTips).drawer_duoci;
				this.settlement_duoci = JSON.parse(pageTips).settlement_duoci;
			}

			this.raskId = window.localStorage.getItem("raskId");
			// console.log(this.raskId);
			this.importDataPLYQ.taskId = this.raskId;

			this.getRaskDetail();
			this.getYfkPre();
			this.getWkPre();
			this.getWkLater();
			this.getYfkLater();
			this.dataYfkjsd();
			this.dataDsh();

			// getSysRole("60").then((res) => {
			//   if (res.success && res.result) {
			//     this.noConfirmTask = true;
			//   }
			// });
		},
		watch: {
			fileList: {
				handler(newV) {
					this.fileList = newV;
				},
				deep: true,
			},
		},
		methods: {
			toDecimal2(x) {
				var f = parseFloat(x);
				if (isNaN(f)) {
					return "0.00";
				}

				var s = x.toString();
				var rs = s.indexOf(".");
				if (rs < 0) {
					rs = s.length;
					s += ".";
				} else {
					s = s.substr(0, s.indexOf(".") + 3);
				}
				while (s.length <= rs + 2) {
					s += "0";
				}

				return s;
			},
			//导出工作者
			exportWorkerExcel(fileName) {
				if (!fileName || typeof fileName != "string") {
					fileName = "导出文件";
				}
				let param = {
					taskId: this.raskId,
					// pageNo:this.tablePageNo,
				};

				exportWorker(param).then((data) => {
					if (!data) {
						this.$message.warning("文件下载失败");
						return;
					} else {
						this.$message.success("导出成功");
					}

					if (typeof window.navigator.msSaveBlob !== "undefined") {
						window.navigator.msSaveBlob(new Blob([data]), fileName + ".xlsx");
					} else {
						let url = window.URL.createObjectURL(new Blob([data]));
						let link = document.createElement("a");
						link.style.display = "none";
						link.href = url;
						link.setAttribute("download", fileName + ".xlsx");
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link); //下载完成移除元素
						window.URL.revokeObjectURL(url); //释放掉blob对象
					}
				});
			},
			closeQRcode(data) {
				this.showWxCode = data;
			},

			openWxCode() {
				this.showWxCode = true;
				// this.bindQRCode();
			},

			delWorker(record) {
				this.$confirm({
					title: "删除用工人员",
					content: "确定删除该用工人员",
					onOk: () => {
						return new Promise((resolve, reject) => {
							delWorker({
								id: record.id
							}).then((res) => {
								if (res.success) {
									this.$refs.qyryTable.refresh(true);
									resolve();
								} else {
									this.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
				});
			},
			loginStatusChange(v) {
				this.queryLoginStatus = v;
				this.$refs.raskTablePLYQ.refresh(true);
			},

			//根据条件查询列表
			checkPayDetailTable() {
				this.$refs.qyryTable.refresh(true);
			},
			//重置列表
			resetPayDetailTable() {
				this.queryParam = {
					userName: "",
					status: undefined,
				};
				this.checkPayDetailTable();
			},
			//异常名单
			importErrorModalOpen() {
				this.importErrorModal = true;
			},
			importErrorModalCancel() {
				this.importErrorModal = false;
			},
			onChangeDateImportSettlement(date, dateString) {
				console.log(date, dateString);
				this.payPeriodStartTime = dateString[0];
				this.payPeriodEndTime = dateString[1];
			},
			importSettlementCancelBtn() {
				this.importSettlementListModal = false;
				this.payPeriodStartTime = "";
				this.payPeriodEndTime = "";
				this.importSettlementSta = {
					exceptionNum: 0, //异常人数
					payNum: 0, //付款人数
					totalAmount: "0.00", //付款总额
				};
				this.importSettlementBatchId = "666";
				this.uploadInfoPLYQ = [];

				this.importSpinning = false;
			},
			importSettlementSubmitPay() {
				if (!this.payPeriodEndTime || !this.payPeriodStartTime) {
					this.$message.info("请选择结算周期");
					return;
				}
				this.importSettlementLoading = true;
				let params = {
					batchId: this.importSettlementBatchId,
					payPeriodEndTime: this.payPeriodEndTime,
					payPeriodStartTime: this.payPeriodStartTime,
					taskId: this.raskId,
				};
				submitImportPay(params).then((res) => {
					this.importSettlementLoading = false;
					if (res.success) {
						window.localStorage.setItem("submenu", "/settlementDetailCheck");
						window.localStorage.setItem("taskId", this.raskId);
						this.$router.push("/settlementDetailCheck/" + res.result.payId);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//导入结算 统计
			getImportSettlementStatic() {
				getImportPayStatistics(this.importSettlementBatchId).then((res) => {
					if (res.success && res.result) {
						this.importSettlementSta = res.result;
						this.importSettlementSta.totalAmount = this.importSettlementSta
							.totalAmount ?
							this.importSettlementSta.totalAmount.toFixed(2) :
							"-";
					}
				});
			},
			uploadImportSettlementList(info) {
				this.importSpinning = true;
				if (info.fileList.length > 1) {
					info.fileList.shift();
				}
				if (info.file.status === "removed" && info.fileList.length === 0) {
					this.importSettlementBatchId = "666";
					this.$refs.importSettlement.refresh(true);
					// this.$refs.qyryTable.refresh(true)
					this.importSpinning = false;
				}
				// console.log(info,info.file, info.fileList);
				this.uploadInfoPLYQ = info.fileList;
				if (info.file.status !== "uploading") {}
				if (info.file.status === "done") {
					this.importSpinning = false;
					if (info.file.response.success) {
						this.importSettlementBatchId = info.file.response.result.batchId;
						console.log("batchid", this.importSettlementBatchId);
						this.$message.success(info.file.response.message);
						// this.$refs.qyryTable.refresh(true)
						this.$refs.importSettlement.refresh(true);
						this.getImportSettlementStatic();
						this.uploadInfoPLYQ = [];
					} else {
						this.$message.error(info.file.response.message);
					}
				}
			},
			// 查询导入的结算名单列表
			getImportSettlementList(parameter) {
				return getImportPayList(
					Object.assign(parameter, {
						batchId: this.importSettlementBatchId,
						status: "10",
					})
				).then((res) => {
					let len = res.result.data;
					if (len.length > 0) {
						this.importDisabled = false;
					}
					return res.result;
				});
			},
			// 查询导入的结算名单列表
			getImportErroeList(parameter) {
				return getImportPayList(
					Object.assign(parameter, {
						batchId: this.importSettlementBatchId,
						status: "20",
					})
				).then((res) => {
					return res.result;
				});
			},
			//导入结算名单
			importSettlementListModalOpen() {
				this.importSettlementListModal = true;
			},
			importSettlementListModalCancel() {
				this.importSettlementListModal = false;
				this.importSettlementBatchId = "666";
				this.payPeriodStartTime = "";
				this.payPeriodEndTime = "";
				this.importSettlementSta = {
					exceptionNum: 0, //异常人数
					payNum: 0, //付款人数
					totalAmount: "0.00", //付款总额
				};

				this.uploadInfoPLYQ = [];

				this.importSpinning = false;
			},
			//不再提示
			noTips() {
				this.invite_duoci = 1;
				let allTips = window.localStorage.getItem("allTipsFlag");
				allTips = JSON.parse(allTips);
				allTips.invite_duoci = 1;
				window.localStorage.setItem("allTipsFlag", JSON.stringify(allTips));
			},
			noTips1() {
				this.settlement_duoci = 1;
				let allTips = window.localStorage.getItem("allTipsFlag");
				allTips = JSON.parse(allTips);
				allTips.settlement_duoci = 1;
				window.localStorage.setItem("allTipsFlag", JSON.stringify(allTips));
			},
			// 发送短信
			sendMsg(record) {
				sendInvitationSms(record.phoneNo).then((res) => {
					if (res.success) {
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//查看结算单
			settlementDetail(record) {
				window.localStorage.setItem("submenu", "/settlementDetailCheck");
				window.localStorage.setItem("taskId", this.raskId);
				this.$router.push("/settlementDetailCheck/" + record.id);

				//  window.localStorage.setItem('taskInfo',JSON.stringify(res.result))
			},
			//批量确认
			handleOkPLYQ() {
				let params = {
					taskId: this.raskId,
					batchId: this.batchIdPLYQ,
				};
				this.confirmLoadingPl = true;
				submitApply(params).then((res) => {
					this.confirmLoadingPl = false;
					if (res.success) {
						// this.$message.success(res.message)
						this.$refs.qyryTable.refresh(true);
						this.handleCancelPLYQ();
						this.wxchatTipsFlag = true;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 汇总批量上传表格人数、金额
			importWorkerStatistics() {
				importWorkerStatistics(this.batchIdPLYQ).then((res) => {
					if (res.success) {
						this.tableInfoPLYQ = res.result;
					}
				});
			},
			//批量邀请取消
			handleCancelPLYQ() {
				this.inviteSpinning = false;
				this.visiblePLYQ = false;
				this.batchIdPLYQ = "666";
				this.queryLoginStatus = undefined;
				this.$refs.raskTablePLYQ.refresh(true);
				this.tableInfoPLYQ = {
					taskAmount: 0.0,
					advanceAmount: 0.0,
					balanceAmount: 0.0,
					feeAmount: 0.0,
					payAmount: 0.0,
					countNum: 0.0,
				};
			},
			// 查询批量上传工作者列表
			raskDataPLYQ(parameter) {
				return queryImportWorkerList(
					Object.assign(parameter, {
						batchId: this.batchIdPLYQ,
						loginStatus: this.queryLoginStatus,
					})
				).then((res) => {
					this.dataPl = res.result.data;
					this.allowSubmit = res.result.data.length === 0 ? false : true;
					return res.result;
				});
			},
			//   批量邀请
			// 上传
			handleChangePLYQ(info) {
				if (info.fileList.length > 1) {
					info.fileList.shift();
				}
				this.inviteSpinning = true;
				if (info.file.status === "removed" && info.fileList.length === 0) {
					this.inviteSpinning = false;
					this.batchIdPLYQ = "666";
					this.$refs.raskTablePLYQ.refresh(true);
					this.tableInfoPLYQ = {
						taskAmount: 0.0,
						advanceAmount: 0.0,
						balanceAmount: 0.0,
						feeAmount: 0.0,
						payAmount: 0.0,
						countNum: 0.0,
					};
				}
				// console.log(info,info.file, info.fileList);
				this.uploadInfoPLYQ = info.fileList;
				if (info.file.status !== "uploading") {}
				if (info.file.status === "done") {
					this.inviteSpinning = false;
					if (info.file.response.success) {
						this.batchIdPLYQ = info.file.response.result.batchId;
						console.log("batchid", this.batchIdPLYQ);
						this.$message.success(info.file.response.message);
						this.$refs.raskTablePLYQ.refresh(true);
						this.importWorkerStatistics();
					} else {
						this.$message.error(info.file.response.message);
					}
				}
			},
			// 批量上传
			plUpload() {
				this.isPl = true;
				this.visibleExamineModal = true;
			},
			// 上传任务结果
			uploadResult(record) {
				this.isPl = false;
				this.uploadRecord = record;
				this.visibleExamineModal = true;
			},
			handleChange(info) {
				let fileList = [...info.fileList];
				// 1. Limit the number of uploaded files
				fileList = fileList.slice(-9);
				// 2. read from response and show file link
				fileList = fileList.map((file) => {
					if (file.response) {
						// Component will show file.url as link
						file.url = "https://oss.zfycloud.com" + "/" + file.response.result;
						file.fileUrl =
							"https://oss.zfycloud.com" + "/" + file.response.result;
						file.fileName = file.name;
					}
					return file;
				});
				this.fileList = fileList;
			},
			beforeUpload(file) {
				const isJpgOrPng = true;
				if (!isJpgOrPng) {
					this.$message.error("上传照片格式不正确!");
					return false;
				}
				const isLt2M = file.size / 1024 / 1024 < 20;
				if (!isLt2M) {
					this.$message.error("上传文件大小不能超过20MB!");
					return false;
				}
				return isJpgOrPng && isLt2M;
			},
			handleRechargeModalOk() {
				this.examinForm.validateFields((err, fieldsValue) => {
					if (err) {
						return;
					}
					if (this.fileList.length === 0) {
						this.$message.error("请上传任务结果！");
						return;
					}
					let isUpload = true;
					this.fileList.forEach((item) => {
						if (item.fileName === undefined) {
							item.status = "error";
							isUpload = false;
						}
					});
					if (!isUpload) {
						this.$message.error("上传文件中有错误文件，请删除后提交");
						return;
					}
					this.confirmLoadingUpload = true;
					const description = this.examinForm.getFieldsValue();
					if (this.isPl) {
						let params = {
							payId: this.record.id,
							resultItems: this.fileList,
							content: description.content,
							taskId: this.record.taskId,
						};
						enterpriseUnifyAdd(Object.assign(params)).then((res) => {
							this.confirmLoadingUpload = false;
							if (res.success) {
								this.$message.success(res.message);
								// this.$refs.yfkTableDetail.refresh(true);
								this.$refs.yfkTable.refresh(true);
								this.handleExamineModalCancel();
								this.visibleTaskDetail = false;
							} else {
								this.$message.error(res.message);
							}
						});
					} else {
						let params = {
							results: [{
								payItemId: this.uploadRecord.id,
								workerId: this.uploadRecord.workerId,
								resultItems: this.fileList,
								content: description.content,
							}, ],
							taskId: this.uploadRecord.taskId,
						};
						enterpriseAdd(Object.assign(params)).then((res) => {
							this.confirmLoadingUpload = false;
							if (res.success) {
								this.$message.success(res.message);
								// this.$refs.yfkTableDetail.refresh(true);
								this.$refs.yfkTable.refresh(true);
								this.handleExamineModalCancel();
								this.visibleTaskDetail = false;
							} else {
								this.$message.error(res.message);
							}
						});
					}
				});
			},
			handleExamineModalCancel() {
				this.examinForm.resetFields();
				this.fileList = [];
				this.confirmLoadingUpload = false;
				this.visibleExamineModal = false;
			},
			// 查询
			handleToSearchEnterprises() {
				this.$refs.rwshTable.refresh(true);
			},
			// 重置
			resetSearchEnterprises() {
				this.queryParam = {
					userName: "",
					auditStatus: undefined,
				};
				this.handleToSearchEnterprises();
			},
			// 取消付款
			cancelPays(record) {
				console.log(record);
				let that = this;
				this.$confirm({
					title: "确定取消付款？",
					onOk() {
						return new Promise((resolve, reject) => {
							cancelPay(record.id).then((res) => {
								if (res.success) {
									that.$message.success(res.message);
									that.$refs.yfkTableDetail.refresh(true);
									that.$refs.yfkTable.refresh(true);
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {
						// console.log('Cancel');
					},
				});
			},
			// 提交结算
			submitPay(record) {
				let that = this;
				this.$confirm({
					title: "确定提交结算？",
					onOk() {
						return new Promise((resolve, reject) => {
							submitPeriodPay({
								payId: record.id,
								taskId: that.raskId
							}).then(
								(res) => {
									if (res.success) {
										that.$message.success(res.message);
										that.$refs.yfkTable.refresh(true);
										window.localStorage.setItem("submenu", "/settlementDetail");
										window.localStorage.setItem(
											"taskInfo",
											JSON.stringify(res.result)
										);
										window.location.reload(true);
										resolve();
									} else {
										that.$message.error(res.message);
										reject();
									}
								}
							);
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {
						// console.log('Cancel');
					},
				});
			},
			// 关闭任务
			closeRask(record) {
				console.log(record);
				let that = this;
				this.$confirm({
					title: "确定关闭任务？",
					content: "关闭任务后，将不能进行任何操作。",
					onOk() {
						return new Promise((resolve, reject) => {
							fulFilTask(that.raskId).then((res) => {
								if (res.success) {
									that.$message.success(res.message);
									that.ret();
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {
						// console.log('Cancel');
					},
				});
			},
			overRask(record) {
				console.log(record);
				let that = this;
				this.$confirm({
					title: "确定完结任务？",
					content: "完结任务后，将不能进行任何操作。",
					onOk() {
						return new Promise((resolve, reject) => {
							taskOver(that.raskId).then((res) => {
								if (res.success) {
									that.$message.success(res.message);
									that.ret();
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {
						// console.log('Cancel');
					},
				});
			},
			// 查看付款详情
			payDetail(record) {
				this.record = record;
				this.visibleTaskDetail = true;
				// this.$refs.yfkTableDetail.refresh(true)
			},

			// 输入金额
			userPayChange(e, item, index) {
				// console.log(e,item);
				let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if (item.payAmount) {
					if (!reg.test(e)) {
						this.$message.info("金额最多2为小数");
						item.payAmount = this.toDecimal2(e);
						this.$set(this.currentSelectedBmry, index, item);
						return;
					}
					let params = item;
					params.isIncludeFee = this.raskDetailInfo.isIncludeFee;
					// console.log('params',params);
					getServiceRule(params).then((res) => {
						if (res.success) {
							item.isAllow = true;
							this.isAllow = true;
							item.fyje = res.result.feeAmount;
							item.fyzje = res.result.totalAmount;
							item.incomeAmount = res.result.incomeAmount;
							this.$set(this.currentSelectedBmry, index, item);
						} else {
							item.isAllow = false;
							this.isAllow = false;
							item.allowMessage = res.message;
							this.allowMessage = res.message;
							this.$message.error(res.message);
						}
					});
				} else {
					item.fyje = 0.0;
					item.fyzje = 0.0;
				}
			},
			// 选择日期
			onChangeDate(date, dateString) {
				console.log(date, dateString);
				this.bmryParams.payPeriodStartTime = dateString[0];
				this.bmryParams.payPeriodEndTime = dateString[1];
			},
			// 查看图片
			showPic(record) {
				this.currentContent = record.content;
				this.picList = record.items;
				this.visiblePicture = true;
			},

			// 获取任务类别名称
			getRaskTypeName(id) {
				getRaskTypeName(id).then((res) => {
					if (res.success) {
						this.raskTypeName = res.result.industryName;
					}
				});
			},
			// 获取任务详情
			getRaskDetail() {
				raskDetail({
					id: this.raskId
				}).then((res) => {
					// console.log('任务详情',res)
					if (res.success) {
						this.raskDetailInfo = res.result;
						if (
							this.raskDetailInfo.status === "20" ||
							this.raskDetailInfo.status === "30" ||
							this.raskDetailInfo.status === "50"
						) {
							this.canCancel = false;
						}
						this.getRaskTypeName(this.raskDetailInfo.taskType);
					}
				});
			},
			// 取消任务
			cancelRask(record) {
				let that = this;
				this.$confirm({
					title: "确定取消该任务?",
					// content: h => <div style="color:red;">Some descriptions</div>,
					onOk() {
						return new Promise((resolve, reject) => {
							raskCancel(that.raskId).then((res) => {
								if (res.success) {
									that.$message.success(res.message);
									that.ret();
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {},
				});
			},
			// 结束报名
			raskEnd(record) {
				let that = this;
				this.$confirm({
					title: "确定结束报名?",
					// content: h => <div style="color:red;">Some descriptions</div>,
					onOk() {
						return new Promise((resolve, reject) => {
							raskEnd(that.raskId).then((res) => {
								if (res.success) {
									that.$message.success(res.message);
									that.ret();
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {},
				});
			},
			// 返回任务详情
			ret() {
				window.localStorage.setItem("submenu", "raskManage");
				this.$router.push("/rask");
			},
			// 查询
			handleToSearchEnterprise() {},
			// 重置
			resetSearchEnterprise() {},
			// 选择标签页
			callback(key) {
				console.log("key", key);
				if (key === "3") {
					this.activeTabsKey = "3";
					this.getRaskDetail();
					this.getYfkPre();
					this.getWkPre();
					this.getWkLater();
					this.getYfkLater();
					this.dataYfkjsd();
					setTimeout(() => {
						this.$refs.yfkTable.refresh(true);
					}, 10);
				} else if (key === "2") {
					this.activeTabsKey = "2";
					this.getRaskDetail();
					setTimeout(() => {
						// this.$refs.bmryTable.refresh(true)
						this.$refs.qyryTable.refresh(true);
					}, 100);
				} else if (key === "4") {
					this.getRaskDetail();
					this.dataDsh();
					setTimeout(() => {
						this.$refs.rwshTable.refresh(true);
					}, 100);
				}
			},
			// 获取报名人员getBmryList
			dataBmry(parameter) {
				return getWorkList(
					Object.assign({
						taskId: this.raskId,
						signStatus: null
					}, parameter)
				).then((res) => {
					// console.log(res)
					if (res.success) {
						this.totalBmry = "报名人员(" + res.result.totalCount + ")";
					}
					return res.result;
				});
			},
			// 获取签约人员getQyryList
			dataQyry(parameter) {
				return getWorkList(
					Object.assign({
						taskId: this.raskId
					}, parameter, this.queryParam)
				).then((res) => {
					if (res.success) {
						this.totalQyry = "用工人员(" + res.result.totalCount + ")";
						if (res.result.data.length > 0) {
							res.result.data.forEach((item) => {
								if (item.signStatus === "30") {
									this.canCancel = false;
								}
							});
						}
					}
					return res.result;
				});
			},
			// 获取预付款列表getYfkList
			dataYfkjsd(parameter) {
				return getOrderList(
					Object.assign({
						taskId: this.raskId
					}, parameter)
				).then((res) => {
					if (res.success) {
						this.totalFkxx = "结算付款(" + res.result.totalCount + ")";
						this.fkjsList = res.result.data;
					}
					return res.result;
				});
			},
			// 获取尾款列表getWkList
			dataWkjsd(parameter) {
				return taskWorkerPayItemList(
					Object.assign({
						payId: this.record.id
					}, parameter)
				).then((res) => {
					this.disabledBtn2 = res.result.data.length === 0 ? false : true;
					if (res.result.data && res.result.data.length > 0) {
						res.result.data.forEach((item) => {
							if (item.payStatus !== "10") {
								this.disabledBtn2 = false;
							}
						});
					}
					return res.result;
				});
			},
			// 获取预付款统计
			getYfkPre() {
				settlementStatistical({
					taskId: this.raskId,
					payType: "10"
				}).then(
					(res) => {
						if (res.success) {
							this.yfkPre = res.result;
						}
					}
				);
			},
			getYfkLater() {
				getPayDetails({
					taskId: this.raskId,
					payType: "10"
				}).then((res) => {
					// console.log(res)
					if (res.success) {
						this.yfkLater = res.result;
						this.notYfuJs = this.yfkLater === null ? true : false;
					}
				});
			},
			// 获取尾款统计
			getWkPre() {
				settlementStatistical({
					taskId: this.raskId,
					payType: "20"
				}).then(
					(res) => {
						if (res.success) {
							this.wkPre = res.result;
						}
					}
				);
			},
			getWkLater() {
				getPayDetails({
					taskId: this.raskId,
					payType: "20"
				}).then((res) => {
					if (res.success) {
						this.wkLater = res.result;
						this.notWkJs = this.wkLater === null ? true : false;
					}
				});
			},
			// 获取审核列表getShList
			dataDsh(parameter) {
				return getByWorkerIds(
					Object.assign({
						taskId: this.raskId
					}, parameter, this.queryParam)
				).then((res) => {
					if (res.success) {
						this.totalRwsh = "任务审核(" + res.result.totalCount + ")";
					}
					return res.result;
				});
			},
			// 报名人员操作
			onSelectChange(selectedRowKeys, selectedRows) {
				let sek = [];
				let ser = [];
				selectedRows.forEach((item, index) => {
					if (item.signStatus === "30") {
						item.payAmount = "";
						item.workerId = item.id;
						item.fyje = 0;
						item.fyzje = 0;
						item.isAllow = true;
						sek.push(item.id);
						ser.push(item);
					}
				});
				this.selectedRowKeys = sek;
				this.bmryParams.selectedTotalBmry = ser;
				this.currentSelectedBmry = JSON.parse(JSON.stringify(ser));
			},
			// 签约
			sign(record) {
				this.isClickYg = true;
				this.hasInvitor = true;
				this.currentItem = record;
				this.selectedRowKeys.length = 1;
				this.bmryParams.selectedTotalBmry = [];
				this.visibleYq = true;
				this.bmryParams.selectedTotalBmry[0] = record;
			},
			signNew(record) {
				let that = this;
				this.$confirm({
					title: "确定用工?",
					// content: h => <div style="color:red;">Some descriptions</div>,
					onOk() {
						return new Promise((resolve, reject) => {
							raskAssign({
								id: record.id
							}).then((res) => {
								if (res.success) {
									that.getRaskDetail();
									// that.$refs.bmryTable.refresh(true)
									that.$refs.qyryTable.refresh(true);
									that.$message.success(res.message);
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {},
				});
			},
			// 拒绝
			refuse(record) {
				let that = this;
				this.$confirm({
					title: "确定拒绝用工?",
					// content: h => <div style="color:red;">Some descriptions</div>,
					onOk() {
						return new Promise((resolve, reject) => {
							refuseAssign({
								id: record.id
							}).then((res) => {
								if (res.success) {
									that.getRaskDetail();
									// that.$refs.bmryTable.refresh(true)
									that.$refs.qyryTable.refresh(true);
									that.$message.success(res.message);
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {},
				});
			},
			// 批量签约
			plSign() {
				this.visible = true;
				this.currentSelectedBmry.forEach((item) => {
					if (item.payAmount === "") {
						item.fyje = 0;
						item.fyzje = 0;
					}
				});
			},
			fiexed(e) {
				this.bmryParams.prePay = Number(this.bmryParams.prePay).toFixed(0);
			},
			// 发起付款
			handleOk() {
				if (
					this.bmryParams.payPeriodEndTime === "" ||
					this.bmryParams.payPeriodEndTime === ""
				) {
					this.$message.error("请选择周期");
					return;
				}
				let isOk = false;
				this.currentSelectedBmry.forEach((item) => {
					if (item.payAmount === "" || Number(item.payAmount) <= 0) {
						this.$message.error("任务金额不能为空或者小于等于零");
						isOk = false;
						return;
					}
					isOk = true;
				});
				if (isOk === false) {
					return;
				}
				this.bmryParams.taskId = this.raskId;
				this.bmryParams.users = this.currentSelectedBmry;
				this.confirmLoading = true;
				addPeriodPay(this.bmryParams).then((res) => {
					this.confirmLoading = false;
					if (res.success) {
						window.localStorage.setItem("submenu", "/settlementDetailCheck");
						window.localStorage.setItem("taskId", this.raskId);
						this.$router.push("/settlementDetailCheck/" + res.result.payId);
						return;
						// this.$message.success(res.message);
						// this.getRaskDetail();
						// // this.$refs.bmryTable.refresh(true)
						// this.$refs.qyryTable.refresh(true);
						// this.handleCancel();
						// this.activeTabsKey = "3";
						// this.getRaskDetail();
						// this.getYfkPre();
						// this.getWkPre();
						// this.getWkLater();
						// this.getYfkLater();
						// this.dataYfkjsd();
						// setTimeout(() => {
						//   this.$refs.yfkTable.refresh(true);
						// }, 10);
						// this.countDown()
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleCancel() {
				this.confirmLoading = false;
				this.visible = false;
			},
			// 付款后的提示
			countDown() {
				let secondsToGo = 50;
				const modal = this.$success({
					title: "操作提示",
					content: `如想查看用工周期付款单，请进入【付款信息】中查看。`,
				});
				setTimeout(() => {
					modal.destroy();
				}, secondsToGo * 1000);
			},
			// 邀请
			invite() {
				this.visibleYq = true;
				this.isClickYg = false;
			},
			invitePLYQ() {
				this.uploadInfoPLYQ = [];
				this.visiblePLYQ = true;
			},
			tofixed() {
				this.inputYfje = Number(this.inputYfje).toFixed(2);
			},
			// 搜索
			onSearchInvite(value) {
				if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.inviteUserPhone)) {
					getUserByPhone(this.inviteUserPhone).then((res) => {
						if (res.result.length > 0 && res.success) {
							this.currentItem = res.result[0];
							this.hasInvitor = true;
							// this.visibleYq = true;//打开搜索结果
							this.invite();
						} else {
							this.hasInvitor = false;
							this.stepTwoShowContentYq = true;
							// this.visibleYq = true;//打开搜索结果
							this.invite(); //打开搜索结果
						}
					});
				} else {
					this.$message.error("手机号格式不正确");
				}
			},
			// 任务金额
			onChangeRwje() {
				if (this.rwje !== 0) {
					this.inputYfje = (this.rwje * this.yfje).toFixed(2);
				}
			},
			// 单选预付金额
			onChangeYfje() {
				if (this.yfje !== 0) {
					this.inputYfje = (this.yfje * this.rwje).toFixed(2);
				}
			},
			// 发送短信
			sendDx() {
				if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.inviteUserPhone)) {
					sendInvitationSms(this.inviteUserPhone).then((res) => {
						if (res.success) {
							this.$message.success(res.message);
							this.handleCancelYq();
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					this.$message.error("手机号格式不正确");
				}
			},
			// 确认发送
			handleOkYq() {
				this.confirmLoadingYq = true;
				console.log(this.currentItem);
				if (!this.isClickYg) {
					let params = Object.assign(
						this.currentItem, {
							taskId: this.raskId
						}, {
							payAmount: this.rwje
						}
					);
					if (this.dataPl.length > 0) {
						params = Object.assign(params, {
							batchId: this.batchIdPLYQ
						});
					}
					phoneInvitation(params).then((res) => {
						this.confirmLoadingYq = false;
						if (res.success) {
							this.batchIdPLYQ = res.result.batchId;
							this.$refs.raskTablePLYQ.refresh(true);
							this.$refs.qyryTable.refresh(true);
							this.handleCancelYq();
							this.importWorkerStatistics();
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					raskAssign(
						Object.assign({
							models: [{
								id: this.currentItem.id
							}]
						}, {
							id: this.raskId
						})
					).then((res) => {
						this.confirmLoadingYq = false;
						if (res.success) {
							this.$message.success(res.message);
							// this.$refs.raskTablePLYQ.refresh(true)
							this.$refs.qyryTable.refresh(true);
							this.handleCancelYq();
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			// 取消邀请
			handleCancelYq() {
				this.visibleYq = false;
				this.inviteUserPhone = "";
				this.rwje = 0;
				this.inputYfje = 0;
				this.yfje = 0.3;
				this.hasInvitor = false;
				this.stepTwoShowContentYq = false;
			},

			// 签约人员
			// 取消签约
			cancelSign(record) {
				let that = this;
				this.$confirm({
					title: "确定取消用工?",
					// content: h => <div style="color:red;">Some descriptions</div>,
					onOk() {
						return new Promise((resolve, reject) => {
							cancelAssign({
								id: record.id
							}).then((res) => {
								if (res.success) {
									that.getRaskDetail();
									that.$message.success(res.message);
									that.$refs.qyryTable.refresh(true);
									resolve();
								} else {
									that.$message.error(res.message);
									reject();
								}
							});
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {},
				});
			},

			// 付款信息
			callbackFk(key) {},
			// 提交预付款
			subYfk() {
				this.tjjsLoading = true;
				taskWorkerPaySubmit({
					taskId: this.raskId,
					payType: "10"
				}).then(
					(res) => {
						this.tjjsLoading = false;
						if (res.success) {
							this.$message.success(res.message);
							this.getRaskDetail();
							this.$refs.yfkTable.refresh(true);
							this.getYfkLater();
						} else {
							this.$message.error(res.message);
						}
					}
				);
			},
			// 提交尾款
			subWk() {
				this.tjjsWkLoading = true;
				taskWorkerPaySubmit({
					taskId: this.raskId,
					payType: "20"
				}).then(
					(res) => {
						this.tjjsWkLoading = false;
						if (res.success) {
							this.$message.success(res.message);
							this.getRaskDetail();
							this.getWkLater();
						} else {
							this.$message.error(res.message);
						}
					}
				);
			},
			//  待审核
			//  通过
			pass(record) {
				let that = this;
				this.$confirm({
					title: "确定通过?",
					// content: h => <div style="color:red;">Some descriptions</div>,
					onOk() {
						return new Promise((resolve, reject) => {
							taskWorkerResult({
								id: record.id,
								auditStatus: "20"
							}).then(
								(res) => {
									if (res.success) {
										that.$message.success(res.message);
										that.$refs.rwshTable.refresh(true);
										resolve();
									} else {
										that.$message.error(res.message);
										reject();
									}
								}
							);
						}).catch(() => console.log("Oops errors!"));
					},
					onCancel() {},
				});
			},
			// 驳回
			noPass(record) {
				this.record = record;
				this.visibleBh = true;
			},
			handleSubmit(e) {
				e.preventDefault();
				this.formBh.validateFields((err, values) => {
					if (!err) {
						let params = Object.assign(values, {
							id: this.record.id,
							auditStatus: "30",
						});
						taskWorkerResult(params).then((res) => {
							if (res.success) {
								this.$message.success(res.message);
								this.$refs.rwshTable.refresh(true);
								this.handleCancelBh();
							} else {
								this.$message.error(res.message);
							}
						});
					}
				});
			},
			handleCancelBh() {
				this.visibleBh = false;
				this.formBh.resetFields();
			},
		},
	};
</script>
<style scoped lang="less">
	.tips_ttttt {
		padding-top: 20px;
		display: flex;
		align-items: center;

		span {
			margin-left: 5px;
		}
	}

	.detail_search {
		margin: 15px 0 24px 0;

		.search_container {
			display: flex;
			align-items: center;

			.search_item {
				.span {
					padding-left: 20px;
				}
			}

			.all_btn {
				padding-left: 40px;
			}
		}
	}

	.task_title_modal {
		text-align: center;
	}

	.settlement_date {
		display: flex;
		align-items: center;
	}

	.modaltips_container {
		.modal_tips {
			padding: 52px 50px 27px 20px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			padding-top: 20px;

			.img {
				width: 43px;
				height: 41px;
				margin-right: 10px;
			}
		}

		.btn_container {
			text-align: center;
		}
	}

	.fk_tab {
		position: relative;
	}

	.add_tips1 {
		position: absolute;
		right: 5%;
		top: -20px;
		// min-wdith: 200px;
		//
		width: 200px;
		height: 60px;
		z-index: 999999;

		.tips_container {
			.gif {
				position: absolute;
				left: 85px;
				top: 65px;
			}

			.tips_box {
				padding: 7px 12px 7px 15px;
				background: #1890ff;
				border-radius: 5px;
				color: #fff;
				position: relative;

				&::after {
					content: "";
					position: absolute;
					left: 45%;
					bottom: -15px;
					width: 0;
					height: 0;
					border-top: 8px solid #1890ff;
					border-left: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-right: 8px solid transparent;
				}

				.tips_text1,
				.tips_text2 {
					font-size: 12px;
				}

				.tips_text2 {
					text-align: right;
					padding-top: 10px;
					cursor: pointer;
				}
			}
		}
	}

	@media screen and (max-width: 1530px) and (min-width: 1399px) {
		.fk_tab {
			.add_tips1 {
				right: 0%;
			}
		}
	}

	@media screen and (max-width: 1630px) and (min-width: 1529px) {
		.fk_tab {
			width: 100%;

			.add_tips1 {
				right: 2%;
			}
		}
	}

	@media screen and (max-width: 1730px) and (min-width: 1629px) {
		.fk_tab {
			width: 100%;

			.add_tips1 {
				right: 3%;
			}
		}
	}

	@media screen and (max-width: 1830px) and (min-width: 1720px) {
		.fk_tab {
			width: 100%;

			.add_tips1 {
				right: 3%;
			}
		}
	}

	.yqry_tips {
		position: relative;
		display: flex;

		.import_excel {
			margin-right: 24px;
		}
	}

	.add_tips {
		position: absolute;
		left: -220px;
		top: 0px;
		// min-wdith: 200px;
		//
		// width: 200px;
		// height: 60px;
		z-index: 666;

		.tips_container {
			.gif {
				position: absolute;
				right: -30px;
				top: 0px;
			}

			.tips_box {
				padding: 7px 12px 7px 15px;
				background: #1890ff;
				border-radius: 5px;
				color: #fff;
				position: relative;

				&::after {
					content: "";
					position: absolute;
					right: -7px;
					top: 9px;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-left: 8px solid #1890ff;
					border-bottom: 8px solid transparent;
				}

				.tips_text1,
				.tips_text2 {
					font-size: 12px;
				}

				.tips_text2 {
					text-align: right;
					padding-top: 10px;
					cursor: pointer;
				}
			}
		}
	}

	.top_plyq {
		display: flex;
		// align-items: center;
		justify-content: space-between;

		.plyq_upload {
			display: flex;
			align-items: flex-start;

			.xzmb {
				padding-left: 10px;
				padding-top: 6px;
			}
		}
	}

	.pl-table {
		border: 1px solid #dadada;
		border-radius: 6px;
		margin: 15px 0 0 0;
	}

	.pl-total {
		margin: 10px 0 30px 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.total-ze {
			color: #33383e;
			font-size: 16px;

			// margin: 15px 15px 0 0;
			.total-zee {
				color: #ff4d4f;
				font-size: 24px;
			}

			.total-zeee {
				color: #ff4d4f;
				font-size: 16px;
			}
		}
	}

	.header-box {
		color: #000000;
		font-size: 14px;
		margin-bottom: 20px;

		.ret {
			color: rgba(0, 0, 0, 0.65);
			cursor: pointer;
		}
	}

	.header-one {
		padding: 27px 24px;
		border: 1px solid #f0f0f0;
		margin-bottom: 16px;
		// border-left: 0;
		// border-right: 0;
		// border-bottom: 0;
	}

	.rask-title {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.rt-left {
			display: flex;
			align-items: flex-end;

			.rt-left-title {
				font-size: 20px;
				color: rgba(0, 0, 0, 0.85);
				margin-right: 16px;
			}

			.rt-left-time {
				font-size: 14px;
				color: rgba(0, 0, 0, 0.65);
			}
		}
	}

	.rask-content {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.85);

		.rc-mgb {
			margin-top: 24px;
			white-space: nowrap;

			&:last-child {
				width: 100%;
				text-align: justify;
				white-space: normal;
			}

			span {
				cursor: pointer;
				color: #1890ff;
			}
		}
	}

	.search {
		width: 100%;
		padding: 16px 0;
		// display: flex;
		align-items: center;
	}

	.search-table {
		margin-top: 16px;
		display: flex;
		align-items: center;
	}

	.model {
		padding: 0;
		list-style: none;

		li {
			display: flex;
			align-items: flex-start;
			margin-bottom: 20px;

			.span {
				width: 80px;
			}

			.mgt-5 {
				margin-top: 5px;
				margin-left: 5px;
			}

			.tag {
				width: 360px;
				display: flex;
				flex-wrap: wrap;

				span {
					margin-bottom: 10px;
				}
			}
		}
	}

	.fkxx-title {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.85);
		margin: 10px 0 25px 0;
	}

	.fkxx-card {
		width: 500px;
		// height: 182px;
		padding: 20px 24px;
		border: 1px solid #f0f0f0;

		.fkxxc-title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 16px;
			font-weight: bold;
		}

		.fkxxc-nn {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.fkxxc-nn-left {
				font-size: 16px;
				color: rgba(0, 0, 0, 0.85);
				font-weight: bold;
			}

			.fkxxc-nn-right {
				font-size: 14px;
				color: rgba(0, 0, 0, 0.85);
				display: flex;
				align-items: center;

				&::before {
					display: inline-block;
					width: 6px;
					height: 6px;
					background: #f5222d;
					border-radius: 50%;
					content: "";
					margin-right: 5px;
				}
			}
		}

		.fkxx-content {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			margin-top: 30px;
			color: rgba(0, 0, 0, 0.85);

			span {
				width: 160px;
			}
		}
	}

	.table-point {
		display: flex;
		align-items: center;

		// justify-content: center;
		span {
			width: 4px;
			height: 4px;
			background: #1890ff;
			border-radius: 50%;
			margin-right: 4px;
		}
	}

	.shrw {
		list-style: none;
		padding: 0;

		li {
			margin: 0 0 20px 0;
		}
	}

	.common-img {
		width: 26px;
		height: 30px;
		margin: 0 10px 0 0;
	}

	.showExpand {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.user-list {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;

		li {
			margin: 10px 10px 0 0;
		}
	}

	.bmry {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.new_ygry {
			display: flex;
			align-items: center;

			.tips {
				margin-left: 12px;
				font-size: 12px;
				display: flex;
				align-items: center;

				span {
					margin-left: 5px;
				}
			}
		}

		.new_yqry {
			color: #1890ff;
			cursor: pointer;
		}
	}

	.fsyq-head {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.fsyq-title {
			font-size: 14px;
			color: rgba(32, 32, 32, 0.65);
			margin: 0 0 14px 0;
		}

		.user-info {
			margin: 24px 0;

			.img {
				width: 100px;
				height: 100px;
				border-radius: 3px;
				margin-bottom: 8px;
				text-align: center;
				line-height: 100px;
				font-size: 40px;
				background: #dddddd;
				border-radius: 50%;
			}

			div {
				text-align: center;
				color: rgba(32, 32, 32, 0.65);
				font-size: 14px;
			}
		}
	}

	.common-content {
		.cc-title {
			padding: 18px 0;
			border-bottom: 1px solid #f0f0f0;
			font-size: 18px;
			color: #000000;
		}

		.cc-desc {
			font-size: 14px;
			color: rgba(32, 32, 32, 0.65);
			margin: 24px 0 0 0;
		}

		.cc-content {
			display: flex;
			align-items: center;
			align-items: center;
			font-size: 16px;
			color: #333333;
			margin: 24px 0 0 0;
		}

		.cc-footer {
			margin: 24px 0;
			padding: 20px 80px;
			width: 640px;
			// height: 95px;
			background: #fafafa;
			border-radius: 3px 0px 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;

			.ccf-left,
			.ccf-right {
				color: #333333;
				font-size: 16px;

				span {
					font-size: 24px;
					color: #ff4d4f;
				}

				div {
					&:last-child {
						margin: 10px 0 0 0;
						font-size: 14px;
						color: rgba(32, 32, 32, 0.65);
					}
				}
			}

			.ccf-right {
				margin-left: 100px;
			}
		}
	}

	.common-opt {
		text-align: center;
		margin: 20px 0 20px 0;
	}

	.no-invite {
		margin: 17px 0 77px 0;
		text-align: center;
		font-size: 14px;
		color: rgba(32, 32, 32, 0.65);
	}

	.pop-content {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 0 16px 0;
		border-bottom: 1px solid #e1e4e8;

		.img {
			width: 60px;
			height: 60px;
			margin-right: 10px;
			border-radius: 50%;
		}

		.person {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.85);
			margin-bottom: 10px;

			span {
				&:last-child {
					font-size: 12px;
					color: rgba(0, 0, 0, 0.65);
					margin-left: 10px;
				}
			}
		}

		.construction {
			width: 230px;
			text-align: justify;
			font-size: 14px;
		}
	}

	.operation {
		display: flex;
		justify-content: flex-end;
	}

	.pic-select {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			margin: 10px 0;
		}
	}

	.wx-login {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: 9999;
		display: flex;
		justify-content: center;
		align-items: center;

		.wx-login-container {
			width: 729px;
			height: 500px;

			.close-arrow {
				display: flex;
				flex-direction: row-reverse;
				margin-bottom: 20px;
			}

			.wx-login-content {
				width: 400px;
				height: 400px;
				// background: #FFFFFF;
				margin: 0 auto;
				position: relative;

				.wx-login-title {
					text-align: center;
					font-size: 20px;
					color: #ffffff;
					margin-bottom: 20px;
				}

				.wx-code {
					width: 322px;
					height: 322px;
					background: #ffffff;
					padding: 20px;
					margin: 0 auto;
				}

				.wx-login-footer {
					font-size: 12px;
					color: #ffffff;
					text-align: center;
					margin-top: 20px;
				}

				.wx-login-save-pic {
					cursor: pointer;
					font-size: 12px;
					color: #ffffff;
					text-align: center;
					margin-top: 20px;
				}

				.wx-login-reget {
					position: absolute;
					top: 0;
					width: 400px;
					height: 430px;
					background: rgba(255, 255, 255, 0.9);
					z-index: 60;
					display: flex;
					justify-content: center;
					align-items: center;

					.wx-login-getbtn {
						background: #3c58f2;
						width: 200px;
						height: 40px;
						font-size: 14px;
						padding: 10px;
					}
				}
			}
		}
	}
</style>
<style scoped>
	/* For demo */
	.ant-carousel>>>.slick-slide {
		text-align: center;
		height: 500px;
		line-height: 160px;
		/* background: #364d79; */
		overflow: hidden;
	}

	.ant-carousel>>>.custom-slick-arrow {
		width: 35px;
		height: 35px;
		font-size: 35px;
		color: #fff;
		background-color: rgba(31, 45, 61, 1);
		border-radius: 50%;
		opacity: 0.3;
	}

	.ant-carousel>>>.custom-slick-arrow:before {
		display: none;
	}

	.ant-carousel>>>.custom-slick-arrow:hover {
		opacity: 0.5;
	}

	.ant-carousel>>>.slick-slide h3 {
		color: #fff;
	}

	.ant-carousel>>>.slick-dots {
		display: none !important;
	}
</style>